import "./App.css";

function App() {
   return (
      <div className="container">
         <h1>Geekflare</h1>
         <p>Demo of deploying frontend application to Cloudflare Pages</p>
      </div>
   );
}

export default App;
